@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  width: 100%;
  height: 100%;
  background-color: #dddddd;
}

/* button,
textarea,
input,
select,
a{
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
 -webkit-user-select: none;
 -khtml-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
  user-select: none;

} */